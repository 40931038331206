import {createRequest} from './network-request'

export class ImageUpload {

    static async upload(src: string, fieldName: string, file) {
        try {
            const formData = new FormData()
            formData.append(fieldName, file)
            return createRequest('/blog/image', 'post', {}, formData, {'Content-Type': 'multipart/form-data'}).send()
                .then((res) => {
                    return res.data
                }).catch(e => {
                    return false
                })
        } catch (e) {
            console.log(e)
            return false
        }

    }

    static async getImageWidthHeight(file) {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader()
                reader.onload = e => {
                    const img = new Image
                    img.onload = () => {
                        resolve({width: img.width, height: img.height})
                    }
                    img.src = reader.result as string
                }
                reader.readAsDataURL(file)
            } catch (e) {
                console.log(e)
                reject(e)
            }
        })

    }

    static async urlToFile(url) {
        return new Promise((resolve, reject) => {
            try {
                const xhr = new XMLHttpRequest()

                xhr.responseType = 'arraybuffer'
                xhr.open('GET', url)

                xhr.onload = function () {
                    let base64, binary, bytes, mediaType

                    bytes = new Uint8Array(xhr.response)
                    //NOTE String.fromCharCode.apply(String, ...
                    //may cause "Maximum call stack size exceeded"
                    binary = [].map.call(bytes, function (byte) {
                        return String.fromCharCode(byte)
                    }).join('')
                    mediaType = xhr.getResponseHeader('content-type')
                    base64 = [
                        'data:',
                        mediaType ? mediaType + ';' : '',
                        'base64,',
                        btoa(binary)
                    ].join('')


                    const arr = base64.split(',')
                    const matches = arr[0].match(/:(.*?);/)
                    if (matches) {
                        const mime = matches[1]
                        const bstr = atob(arr[1])
                        let n = bstr.length
                        const u8arr = new Uint8Array(n)
                        while (n--) {
                            u8arr[n] = bstr.charCodeAt(n)
                        }
                        resolve(new File([u8arr], ImageUpload.generateHash(8) + '.jpg', {type: mime}))
                    } else {
                        reject()
                    }
                }
                xhr.onerror = (e)=> {
                    reject(e)
                }

                xhr.send()
            }catch (e) {
                console.log(e)
            }

        })
    }

    /******************
     *  Generate Random Hash
     ******************/
    static generateHash(length: number) {
        let text = ''
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length))
        }
        return text
    }

}
