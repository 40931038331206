/**
 * Build styles
 */
require('./index.css').toString()

const svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 8 13" width="8" height="13">\n' +
    '  <g>\n' +
    '    <path d="M3.6346,5.7007L3.5712,5.6376l0.0662-0.0658l1.3701-1.3701c0.144-0.144,0.2131-0.3054,0.2132-0.4781\n' +
    '\t\tc0.0057-0.1902-0.0577-0.3457-0.2017-0.4897C4.8749,3.0897,4.7136,3.0206,4.535,3.0264C4.3498,3.0368,4.176,3.1196,4.051,3.2567\n' +
    '\t\tL2.7351,4.5725C2.7174,4.5878,2.6976,4.5997,2.6795,4.6139l-0.068,0.0677l-0.068-0.0677C2.5254,4.5997,2.5057,4.5878,2.4879,4.5725\n' +
    '\t\tL1.1721,3.2567c-0.125-0.1372-0.2987-0.2199-0.484-0.2304C0.5095,3.0206,0.3483,3.0897,0.2042,3.2337\n' +
    '\t\tc-0.144,0.144-0.2074,0.2996-0.2017,0.4897c0,0.1728,0.0692,0.3342,0.2131,0.4781l1.3702,1.3701l0.0661,0.0658L1.5885,5.7007\n' +
    '\t\tL0.227,7.0622C0.0933,7.1867,0.0128,7.358,0.0023,7.5404c-0.0041,0.1837,0.0716,0.3602,0.2075,0.4839\n' +
    '\t\tc0.144,0.144,0.2996,0.2074,0.4839,0.2074c0.184-0.0058,0.3581-0.0846,0.4839-0.2189L2.5,6.6905\n' +
    '\t\tc0.0145-0.0122,0.0303-0.0221,0.0456-0.0327l0.066-0.0656l0.066,0.0656c0.0153,0.0106,0.0312,0.0205,0.0456,0.0327l1.3224,1.3223\n' +
    '\t\tc0.1259,0.1344,0.2999,0.2131,0.484,0.2189c0.1843,0,0.3399-0.0634,0.484-0.2074c0.1358-0.1237,0.2114-0.3003,0.2074-0.4839\n' +
    '\t\tC5.2103,7.358,5.1298,7.1867,4.9961,7.0622L3.6346,5.7007z"/>\n' +
    '    <path d="M7.8857,9.4216C7.8116,9.3644,7.7059,9.3348,7.5639,9.3348H6.1522c0.0445-0.0677,0.089-0.127,0.1334-0.1757\n' +
    '\t\tc0.0995-0.1229,0.2858-0.2942,0.5588-0.5165c0.271-0.2222,0.4635-0.3831,0.58-0.4869C7.5386,8.0521,7.6529,7.9081,7.763,7.7261\n' +
    '\t\tC7.8752,7.542,7.9302,7.3367,7.9302,7.1144c0-0.1418-0.0275-0.2793-0.0804-0.4127C7.7968,6.5705,7.7227,6.452,7.6275,6.3504\n' +
    '\t\tC7.5301,6.2509,7.4201,6.1705,7.2931,6.1133C7.0983,6.0275,6.8634,5.9842,6.5882,5.9842c-0.2286,0-0.4297,0.0339-0.599,0.1016\n' +
    '\t\tC5.8179,6.1525,5.6782,6.2403,5.5659,6.3504c-0.11,0.11-0.1927,0.2327-0.2497,0.3682c-0.055,0.1354-0.0825,0.2667-0.0825,0.3895\n' +
    '\t\tc0,0.1038,0.0296,0.1863,0.0889,0.2456s0.1334,0.0889,0.2243,0.0889c0.1016,0,0.1799-0.0339,0.2329-0.0973\n' +
    '\t\tc0.0529-0.0656,0.1016-0.1588,0.1439-0.2794C5.966,6.9451,5.9956,6.871,6.0147,6.8456c0.1354-0.2074,0.3217-0.3132,0.5567-0.3132\n' +
    '\t\tc0.1121,0,0.2159,0.0253,0.3111,0.0762c0.0931,0.0507,0.1693,0.1227,0.2243,0.2116c0.0551,0.0931,0.0826,0.1947,0.0826,0.3112\n' +
    '\t\tc0,0.1057-0.0275,0.2138-0.0784,0.3259C7.0602,7.5674,6.984,7.6754,6.8867,7.7811C6.7893,7.887,6.6645,7.9928,6.5184,8.0965\n' +
    '\t\tC6.4337,8.1516,6.2962,8.2701,6.1036,8.4479C5.9109,8.6236,5.695,8.8374,5.4537,9.0871c-0.0571,0.0614-0.11,0.1461-0.1587,0.2518\n' +
    '\t\tC5.2463,9.4469,5.221,9.5337,5.221,9.5972c0,0.1016,0.038,0.1904,0.1143,0.2645C5.4114,9.9358,5.5173,9.974,5.6527,9.974h1.9896\n' +
    '\t\tc0.1207,0,0.2074-0.0297,0.2667-0.0911c0.0593-0.0614,0.0889-0.1375,0.0889-0.2264C7.9979,9.5591,7.9598,9.4807,7.8857,9.4216z"/>\n' +
    '  </g>\n' +
    '</svg>\n'

/**
 * Subscript Tool for the Editor.js
 *
 * Allows to wrap inline fragment and style it somehow.
 */
class Subscript {
    /**
     * Class name for term-tag
     *
     * @type {string}
     */
    static get CSS() {
        return 'cdx-subscript'
    };

    /**
     * @param {{api: object}}  - Editor.js API
     */
    constructor({api}) {
        this.api = api

        /**
         * Toolbar Button
         *
         * @type {HTMLElement|null}
         */
        this.button = null

        /**
         * Tag represented the term
         *
         * @type {string}
         */
        this.tag = 'SUB'

        /**
         * CSS classes
         */
        this.iconClasses = {
            base: this.api.styles.inlineToolButton,
            active: this.api.styles.inlineToolButtonActive,
        }
    }

    /**
     * Specifies Tool as Inline Toolbar Tool
     *
     * @returns {boolean}
     */
    static get isInline() {
        return true
    }

    /**
     * Create button element for Toolbar
     *
     * @returns {HTMLElement}
     */
    render() {
        this.button = document.createElement('button')
        this.button.type = 'button'
        this.button.classList.add(this.iconClasses.base)
        this.button.innerHTML = this.toolboxIcon

        return this.button
    }

    /**
     * Wrap/Unwrap selected fragment
     *
     * @param {Range} range - selected fragment
     */
    surround(range) {
        if (!range) {
            return
        }

        const termWrapper = this.api.selection.findParentTag(this.tag, Subscript.CSS)

        /**
         * If start or end of selection is in the highlighted block
         */
        if (termWrapper) {
            this.unwrap(termWrapper)
        } else {
            this.wrap(range)
        }
    }

    /**
     * Wrap selection with term-tag
     *
     * @param {Range} range - selected fragment
     */
    wrap(range) {
        /**
         * Create a wrapper for highlighting
         */
        const sub = document.createElement(this.tag)

        sub.classList.add(Subscript.CSS)

        /**
         * SurroundContent throws an error if the Range splits a non-Text node with only one of its boundary points
         *
         * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range/surroundContents}
         *
         * // range.surroundContents(span);
         */
        sub.appendChild(range.extractContents())
        range.insertNode(sub)

        /**
         * Expand (add) selection to highlighted block
         */
        this.api.selection.expandToTag(sub)
    }

    /**
     * Unwrap term-tag
     *
     * @param {HTMLElement} termWrapper - term wrapper tag
     */
    unwrap(termWrapper) {
        /**
         * Expand selection to all term-tag
         */
        this.api.selection.expandToTag(termWrapper)

        const sel = window.getSelection()
        const range = sel.getRangeAt(0)

        const unwrappedContent = range.extractContents()

        /**
         * Remove empty term-tag
         */
        termWrapper.parentNode.removeChild(termWrapper)

        /**
         * Insert extracted content
         */
        range.insertNode(unwrappedContent)

        /**
         * Restore selection
         */
        sel.removeAllRanges()
        sel.addRange(range)
    }

    /**
     * Check and change Term's state for current selection
     */
    checkState() {
        const termTag = this.api.selection.findParentTag(this.tag, Subscript.CSS)

        this.button.classList.toggle(this.iconClasses.active, !!termTag)
    }

    /**
     * Get Tool icon's SVG
     *
     * @returns {string}
     */
    get toolboxIcon() {
        return svgIcon
    }

    /**
     * Sanitizer rule
     *
     * @returns {{s: {class: string}}}
     */
    static get sanitize() {
        return {
            sub: {
                class: Subscript.CSS,
            },
        }
    }
}

export default Subscript
