/**
 * Build styles
 */
require('./index.css').toString()

const svgIcon = '<svg width="18" height="14">\n' +
    '    <path d="M2.833 8v1.95a1.7 1.7 0 0 0 1.7 1.7h3.45V8h-5.15zm0-2h5.15V2.35h-3.45a1.7 1.7 0 0 0-1.7 1.7V6zm12.3 2h-5.15v3.65h3.45a1.7 1.7 0 0 0 1.7-1.7V8zm0-2V4.05a1.7 1.7 0 0 0-1.7-1.7h-3.45V6h5.15zM4.533.1h8.9a3.95 3.95 0 0 1 3.95 3.95v5.9a3.95 3.95 0 0 1-3.95 3.95h-8.9a3.95 3.95 0 0 1-3.95-3.95v-5.9A3.95 3.95 0 0 1 4.533.1z"/>\n' +
    '</svg>\n'

/**
 * @typedef {object} TableConfig
 * @property {string} placeholder - placeholder for the empty Table
 * @property {boolean} preserveBlank - Whether or not to keep blank Tables when saving editor data
 */

/**
 * @typedef {Object} TableData
 * @description Tool's input and output data format
 * @property {String} html — Table's content. Can include HTML tags: <a><b><i>
 * @property {String} style — Table's style config.
 */
class Table {

    /**
     * Render plugin`s main Element and fill it with saved data
     *
     * @param {object} params - constructor params
     * @param {TableData} params.data - previously saved data
     * @param {TableConfig} params.config - user config for Tool
     * @param {object} params.api - editor.js api
     * @param {boolean} readOnly - read only mode flag
     */
    constructor({data, block, config, api, readOnly}) {
        this.api = api
        this.id = block.id
        this.readOnly = readOnly

        this._CSS = {
            block: this.api.styles.block,
            settingsButton: this.api.styles.settingsButton,
            settingsButtonActive: this.api.styles.settingsButtonActive,
            wrapper: 'ce-table'
        }

        /**
         * Generate instance id for tinymce
         * @type {string}
         */
        this._instanceId = `tinymce-${this.id}`

        /**
         * List of settings buttons
         * @type {HTMLElement[]}
         */
        this.settingsButtons = []

        /**
         * Block's data
         * @type {TableData}
         * @private
         */
        this._data = {
            html: data.html || '',
            style: data.style || 'DEFAULT'
        }

        this._element = this.drawView()

        this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false

        this.data = data
    }

    /**
     * Create Tool's view
     * @return {HTMLElement}
     * @private
     */
    drawView() {
        const container = this._make('div', [this._CSS.wrapper, this._CSS.block], {
            contentEditable: true
        })

        const textarea = this._make('textarea', [], {
            id: this._instanceId,
            value: this.data.html
        })
        container.appendChild(textarea)

        return container
    }

    /**
     * Return Tool's view
     *
     * @returns {HTMLDivElement}
     */
    render() {
        if (!window['tinymce'].get(this._instanceId)) {
            setTimeout(() => {
                this.initTinyMCE()
            }, 1000)
        }
        return this._element
    }


    destroy() {
        const tinyInstance = window['tinymce'].get(this._instanceId)
        !!tinyInstance && tinyInstance.destroy()
    }

    moved(event) {
        this.initTinyMCE()
    }

    initTinyMCE() {
        const selector = `#${this._instanceId}`

        window['tinymce'].remove(selector)

        window['tinymce'].init({
            selector: selector,
            height: 400,
            plugins: 'table code link lists',
            menubar: '',
            toolbar: 'undo redo | bold italic underline strikethrough superscript subscript link | alignleft aligncenter alignright alignjustify | table | numlist bullist |code',
            content_css: '/assets/css/style.css, /assets/css/main.css',
            forced_root_block: false,
            table_default_attributes: {
                border: '1',
                width: '100%'
            },
            valid_styles: {
                'td': 'text-align width height',
                'span': 'text-decoration'
            }
        })

        window['tinymce'].get(this._instanceId).on('Change', e => {
            document.getElementById(this._instanceId).value = window['tinymce'].get(this._instanceId).getContent().replace(/\n|\r/g, '')
            window.dispatchEvent(new Event('tableUpdated'))
        })

        // window['tinymce'].get(this._instanceId).on('input', e => {
        //     document.getElementById(this._instanceId).value = window['tinymce'].get(this._instanceId).getContent().replace(/\n|\r/g, '')
        // })
    }

    /**
     * Create Block's settings block
     *
     * @returns {HTMLElement}
     */
    renderSettings() {
        const holder = document.createElement('DIV')

        /** Add type selectors */
        this.styles.forEach(style => {
            const selectTypeButton = document.createElement('SPAN')

            selectTypeButton.classList.add(this._CSS.settingsButton)

            /**
             * Highlight current style button
             */
            if (this.currentStyle.name === style.name) {
                selectTypeButton.classList.add(this._CSS.settingsButtonActive)
            }

            /**
             * Add SVG icon
             */
            selectTypeButton.innerHTML = style.svg

            /**
             * Save style to its button
             */
            selectTypeButton.dataset.style = style.name

            /**
             * Set up click handler
             */
            selectTypeButton.addEventListener('click', () => {
                this.setStyle(style.name)
            })

            /**
             * Append settings button to holder
             */
            holder.appendChild(selectTypeButton)

            /**
             * Save settings buttons
             */
            this.settingsButtons.push(selectTypeButton)
        })

        return holder
    }


    /**
     * Callback for Block's settings buttons
     *
     * @param {string} style - style to set
     */
    setStyle(style) {
        this.data['style'] = style
        window.dispatchEvent(new Event('tableUpdated'))
        /**
         * Highlight button by selected style
         */
        this.settingsButtons.forEach(button => {
            button.classList.toggle(this._CSS.settingsButtonActive, button.dataset.style === style)
        })
    }

    /**
     * Validate Table block data:
     * - check for emptiness
     *
     * @param {TableData} savedData — data received after saving
     * @returns {boolean} false if saved data is not correct, otherwise true
     * @public
     */
    validate(savedData) {
        return !(savedData.html.trim() === '' && !this._preserveBlank)
    }

    /**
     * Extract Tool's data from the view
     * @param {HTMLDivElement} toolsContent - Table tools rendered view
     * @returns {TableData} - saved data
     * @public
     */
    save(toolsContent) {
        const text = toolsContent.querySelector(`#${this._instanceId}`)
        return {
            html: text.value || '',
            style: this._data.style || 'DEFAULT'
        }
    }

    /**
     * Sanitizer rules
     */
    static get sanitize() {
        return {
            html: {
                table: true,
                thead: true,
                tbody: true,
                th: true,
                tr: true,
                td: true,
                br: true,
                a: {
                    href: true,
                    target: true
                },
                span: {
                    style: true
                },
                strong: true,
                em: true,
                ul: true,
                ol: true,
                li: true
            }
        }
    }

    /**
     * Returns true to notify the core that read-only mode is supported
     *
     * @return {boolean}
     */
    static get isReadOnlySupported() {
        return true
    }

    /**
     * Get current Tools`s data
     * @returns {TableData} Current data
     * @private
     */
    get data() {
        const tinyInstance = window['tinymce'].get(this._instanceId)
        if (tinyInstance)
            this._data.html = tinyInstance.getContent()
        return this._data
    }

    /**
     * Store data in plugin:
     * - at the this._data property
     * - at the HTML
     *
     * @param {TableData} data — data to set
     * @private
     */
    set data(data) {
        this._data = Object.assign(this._data, data)
    }

    /**
     * Get current style
     *
     * @returns {style}
     */
    get currentStyle() {
        let style = this.styles.find(item => item.name === this._data.style)

        if (!style) {
            style = this.defaultStyle
        }

        return style
    }

    /**
     * Return default style
     *
     * @returns {style}
     */
    get defaultStyle() {
        return this.styles[0]
    }

    /**
     * @typedef {object} style
     * @property {string} name - style name
     * @property {string} svg - icon
     */

    /**
     * Available table style
     *
     * @returns {style[]}
     */
    get styles() {
        return [
            {
                name: 'DEFAULT',
                svg: '<svg width="17" height="17" xmlns="http://www.w3.org/2000/svg"><path d="M12.6,2.2c-0.6-0.5-1.3-0.9-2-1.1C9.9,0.9,9,0.8,7.9,0.8h-4C3.2,0.8,2.7,1,2.4,1.3C2.1,1.6,2,2.1,2,2.7V14 c0,0.5,0,0.9,0.1,1.2c0.1,0.3,0.3,0.5,0.6,0.7c0.3,0.2,0.7,0.3,1.3,0.3h4c0.7,0,1.3,0,1.9-0.1c0.6-0.1,1.1-0.2,1.6-0.5 c0.5-0.2,0.9-0.5,1.3-0.9c0.5-0.5,1-1,1.3-1.6c0.3-0.6,0.6-1.3,0.7-2.1C15,10.2,15,9.4,15,8.5C15,5.7,14.2,3.6,12.6,2.2z M10.3,13 c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.7,0-1.2,0H5.1V3.3h2c0.9,0,1.8,0.1,2.4,0.3c0.7,0.2,1.2,0.7,1.7,1.4 c0.5,0.8,0.7,1.9,0.7,3.4C11.9,10.6,11.4,12.1,10.3,13z"/></svg>',
            },
            {
                name: 'SCROLL_LIST',
                svg: '<svg width="17" height="17" xmlns="http://www.w3.org/2000/svg"><path d="M13.9,11.2c0,0.8-0.2,1.6-0.6,2.2c-0.4,0.7-1,1.2-1.9,1.5s-1.8,0.6-2.9,0.6c-1.3,0-2.4-0.3-3.3-0.8 c-0.6-0.4-1.1-0.9-1.5-1.5c-0.4-0.6-0.6-1.2-0.6-1.8c0-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.5,0.1,0.7,0.3 s0.4,0.5,0.5,0.8c0.2,0.4,0.4,0.8,0.6,1.1C6.3,12.8,6.6,13,7,13.2c0.4,0.2,0.8,0.3,1.4,0.3c0.8,0,1.5-0.2,2-0.6 c0.5-0.4,0.8-0.9,0.8-1.4c0-0.5-0.1-0.8-0.4-1.1c-0.3-0.3-0.6-0.5-1.1-0.6C9.3,9.6,8.7,9.5,8,9.3C7,9.1,6.2,8.8,5.5,8.5 C4.8,8.2,4.3,7.8,3.9,7.2c-0.4-0.5-0.6-1.2-0.6-2c0-0.7,0.2-1.4,0.6-2c0.4-0.6,1-1,1.8-1.3c0.8-0.3,1.7-0.5,2.7-0.5 c0.8,0,1.6,0.1,2.2,0.3c0.6,0.2,1.1,0.5,1.5,0.8c0.4,0.3,0.7,0.7,0.9,1.1c0.2,0.4,0.3,0.7,0.3,1.1c0,0.3-0.1,0.6-0.3,0.9 c-0.2,0.3-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.7-0.2c-0.2-0.2-0.3-0.4-0.5-0.8c-0.2-0.5-0.5-0.9-0.9-1.2C9.6,3.6,9,3.4,8.3,3.4 C7.6,3.4,7,3.6,6.6,3.9C6.1,4.2,5.9,4.6,5.9,5c0,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.4,0.4,0.6,0.5C7,6.4,7.3,6.5,7.5,6.6 c0.3,0.1,0.7,0.2,1.3,0.4c0.8,0.2,1.5,0.4,2.1,0.6c0.6,0.2,1.2,0.5,1.6,0.8c0.4,0.3,0.8,0.7,1,1.2C13.8,10,13.9,10.5,13.9,11.2z"/></svg>',
            }
        ]
    }

    /**
     * Used by Editor paste handling API.
     * Provides configuration to handle TABLE tags.
     *
     * @returns {{tags: string[]}}
     */
    static get pasteConfig() {
        return {
            tags: ['TABLE']
        }
    }

    /**
     * Icon and title for displaying at the Toolbox
     *
     * @return {{icon: string, title: string}}
     */
    static get toolbox() {
        return {
            icon: svgIcon,
            title: 'Table'
        }
    }

    _make(tagName, classNames = null, attributes = {}) {
        const el = document.createElement(tagName)

        if (Array.isArray(classNames)) {
            el.classList.add(...classNames)
        } else if (classNames) {
            el.classList.add(classNames)
        }

        for (const attrName in attributes) {
            el[attrName] = attributes[attrName]
        }

        return el
    }
}

export default Table
