/**
 * Build styles
 */
require('./index.css').toString()

class ButtonLink {

    static get isReadOnlySupported() {
        return true
    }

    static get toolbox() {
        return {
            icon: '<svg width="18px" height="18px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve"><path d="M13 1v4h1v-4h-1zM19.403 3.578l-3.109 2.516 0.628 0.778 3.109-2.519c0.003 0-0.628-0.775-0.628-0.775zM21.409 10.313l-3.897-0.9-0.225 0.975 3.897 0.9 0.225-0.975zM5.819 11.287l3.897-0.9-0.225-0.975-3.897 0.9 0.225 0.975zM6.969 4.353l3.109 2.519 0.628-0.778-3.109-2.519-0.628 0.778zM19 13.491c0-0.822-0.666-1.491-1.5-1.491-0.828 0-1.5 0.675-1.5 1.491v4.509h-1v-9.491c0-0.834-0.666-1.509-1.5-1.509-0.828 0-1.5 0.666-1.5 1.509v10.291c-2.059-2.2-4.769-4.619-5.878-3.503-1.087 1.094 1.716 4.106 5.625 10.688 1.766 2.966 3.994 5.016 7.753 5.016 4.141 0 7.5-3.359 7.5-7.5v-7.994c0-0.831-0.666-1.506-1.5-1.506-0.828 0-1.5 0.675-1.5 1.506v2.494h-1v-3.509c0-0.825-0.666-1.491-1.5-1.491-0.828 0-1.5 0.662-1.5 1.491v2.509h-1v-3.509z"></path></svg>',
            title: 'ButtonLink',
        }
    }

    get CSS() {
        return {
            baseClass: this.api.styles.block,
            wrapper: 'cdx-buttonLink',
            text: 'cdx-buttonLink__text',
            url: 'cdx-buttonLink__url',
            color: 'cdx-buttonLink__color',
            outline: 'cdx-buttonLink__outline',
            input: this.api.styles.input
        }
    }

    constructor({data, config, api, readOnly}) {
        this.api = api
        this.readOnly = readOnly

        this.data = {
            text: data.text || '',
            url: data.url || '',
            color: data.color || 'primary',
            outline: data.outline || false
        }
    }

    render() {
        const container = this._make('div', [this.CSS.baseClass, this.CSS.wrapper])


        const hints = this._make('div', [], {
            innerHTML: 'Button Link',
            style: 'margin-bottom: 8px;'
        })
        container.appendChild(hints)

        const text = this._make('input', [this.CSS.input, this.CSS.text], {
            contentEditable: !this.readOnly,
            value: this.data.text,
            placeholder: 'Button Text',
            style: 'margin-bottom: 8px;'
        })
        container.appendChild(text)

        const url = this._make('input', [this.CSS.input, this.CSS.url], {
            contentEditable: !this.readOnly,
            value: this.data.url,
            placeholder: 'Link',
            style: 'margin-bottom: 8px;'
        })
        container.appendChild(url)


        const colorSelector = this._make('select', [this.CSS.input, this.CSS.color], {
            style: 'margin-bottom: 8px;'
        })
        const option = ['primary', 'success', 'info', 'warning', 'danger']
        option.map(item => {
            colorSelector.appendChild(
                this._make('option', [], {
                    value: item,
                    text: item.charAt(0).toUpperCase() + item.slice(1),
                    selected: this.data.color === item
                })
            )
        })
        container.appendChild(colorSelector)

        const label = this._make('label', [])
        label.appendChild(
            this._make('input', [this.CSS.outline], {
                type: 'checkbox',
                checked: this.data.outline,
                style: 'margin-right: 8px;'
            })
        )
        label.appendChild(
            this._make('span', [], {
                innerText: 'Outline Button'
            })
        )
        container.appendChild(label)



        return container
    }

    save(buttonLinkElement) {
        const text = buttonLinkElement.querySelector(`.${this.CSS.text}`)
        const url = buttonLinkElement.querySelector(`.${this.CSS.url}`)
        const color = buttonLinkElement.querySelector(`.${this.CSS.color}`)
        const outline = buttonLinkElement.querySelector(`.${this.CSS.outline}`)

        return Object.assign(this.data, {
            text: text.value,
            url: url.value,
            color: color.options[color.selectedIndex].value,
            outline:  outline.checked
        })
    }

    static get sanitize() {
        return {
            text: {},
            url: {},
            color: {},
            outline: {}
        }
    }

    _make(tagName, classNames = null, attributes = {}) {
        const el = document.createElement(tagName)

        if (Array.isArray(classNames)) {
            el.classList.add(...classNames)
        } else if (classNames) {
            el.classList.add(classNames)
        }

        for (const attrName in attributes) {
            el[attrName] = attributes[attrName]
        }

        return el
    }
}

export default ButtonLink
