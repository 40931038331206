/**
 * Build styles
 */
require('./index.css').toString();

const svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 8 13" width="8" height="13">\n' +
    '  <g>\n' +
    '    <path d="M3.6346,7.4377L3.5712,7.3745l0.0662-0.0658l1.3701-1.3701c0.144-0.144,0.2131-0.3054,0.2132-0.4781\n' +
    '\t\tc0.0057-0.1902-0.0577-0.3457-0.2017-0.4897C4.8749,4.8267,4.7136,4.7575,4.535,4.7633C4.3498,4.7738,4.176,4.8566,4.051,4.9937\n' +
    '\t\tL2.7351,6.3095C2.7174,6.3248,2.6976,6.3366,2.6795,6.3509l-0.068,0.0677l-0.068-0.0677C2.5254,6.3366,2.5057,6.3248,2.4879,6.3095\n' +
    '\t\tL1.1721,4.9937c-0.125-0.1372-0.2987-0.2199-0.484-0.2304C0.5095,4.7575,0.3483,4.8267,0.2042,4.9707\n' +
    '\t\tc-0.144,0.144-0.2074,0.2996-0.2017,0.4897c0,0.1728,0.0692,0.3342,0.2131,0.4781l1.3702,1.3701l0.0661,0.0658L1.5885,7.4377\n' +
    '\t\tL0.227,8.7992C0.0933,8.9237,0.0128,9.095,0.0023,9.2774c-0.0041,0.1837,0.0716,0.3602,0.2075,0.4839\n' +
    '\t\tc0.144,0.144,0.2996,0.2074,0.4839,0.2074c0.184-0.0058,0.3581-0.0846,0.4839-0.2189L2.5,8.4275\n' +
    '\t\tc0.0145-0.0122,0.0303-0.0221,0.0456-0.0327l0.066-0.0656l0.066,0.0656c0.0153,0.0106,0.0312,0.0205,0.0456,0.0327l1.3224,1.3223\n' +
    '\t\tc0.1259,0.1344,0.2999,0.2131,0.484,0.2189c0.1843,0,0.3399-0.0634,0.484-0.2074c0.1358-0.1237,0.2114-0.3003,0.2074-0.4839\n' +
    '\t\tC5.2103,9.095,5.1298,8.9237,4.9961,8.7992L3.6346,7.4377z"/>\n' +
    '    <path d="M7.8857,6.4686C7.8116,6.4114,7.7059,6.3818,7.5639,6.3818H6.1522c0.0445-0.0677,0.089-0.127,0.1334-0.1757\n' +
    '\t\tc0.0995-0.1229,0.2858-0.2942,0.5588-0.5165c0.271-0.2222,0.4635-0.3831,0.58-0.4869C7.5386,5.0992,7.6529,4.9552,7.763,4.7731\n' +
    '\t\tc0.1122-0.1841,0.1672-0.3895,0.1672-0.6117c0-0.1418-0.0275-0.2793-0.0804-0.4127C7.7968,3.6175,7.7227,3.499,7.6275,3.3975\n' +
    '\t\tC7.5301,3.298,7.4201,3.2175,7.2931,3.1603C7.0983,3.0746,6.8634,3.0312,6.5882,3.0312c-0.2286,0-0.4297,0.0339-0.599,0.1016\n' +
    '\t\tC5.8179,3.1995,5.6782,3.2873,5.5659,3.3975c-0.11,0.11-0.1927,0.2327-0.2497,0.3682c-0.055,0.1354-0.0825,0.2667-0.0825,0.3895\n' +
    '\t\tc0,0.1038,0.0296,0.1863,0.0889,0.2456s0.1334,0.0889,0.2243,0.0889c0.1016,0,0.1799-0.0339,0.2329-0.0973\n' +
    '\t\tc0.0529-0.0656,0.1016-0.1588,0.1439-0.2794c0.0424-0.1207,0.072-0.1947,0.0911-0.2202c0.1354-0.2074,0.3217-0.3132,0.5567-0.3132\n' +
    '\t\tc0.1121,0,0.2159,0.0253,0.3111,0.0762c0.0931,0.0507,0.1693,0.1227,0.2243,0.2116C7.1619,3.9604,7.1894,4.062,7.1894,4.1785\n' +
    '\t\tc0,0.1057-0.0275,0.2138-0.0784,0.3259C7.0602,4.6145,6.984,4.7224,6.8867,4.8281C6.7893,4.934,6.6645,5.0399,6.5184,5.1435\n' +
    '\t\tC6.4337,5.1987,6.2962,5.3171,6.1036,5.495C5.9109,5.6706,5.695,5.8844,5.4537,6.1342c-0.0571,0.0614-0.11,0.1461-0.1587,0.2518\n' +
    '\t\tC5.2463,6.4939,5.221,6.5807,5.221,6.6443c0,0.1016,0.038,0.1904,0.1143,0.2645C5.4114,6.9829,5.5173,7.021,5.6527,7.021h1.9896\n' +
    '\t\tc0.1207,0,0.2074-0.0297,0.2667-0.0911c0.0593-0.0614,0.0889-0.1375,0.0889-0.2264C7.9979,6.6061,7.9598,6.5278,7.8857,6.4686z"/>\n' +
    '  </g>\n' +
    '</svg>'

/**
 * Superscript Tool for the Editor.js
 *
 * Allows to wrap inline fragment and style it somehow.
 */
class Superscript {
  /**
   * Class name for term-tag
   *
   * @type {string}
   */
  static get CSS() {
    return 'cdx-superscript';
  };

  /**
   * @param {{api: object}}  - Editor.js API
   */
  constructor({ api }) {
    this.api = api;

    /**
     * Toolbar Button
     *
     * @type {HTMLElement|null}
     */
    this.button = null;

    /**
     * Tag represented the term
     *
     * @type {string}
     */
    this.tag = 'SUP';

    /**
     * CSS classes
     */
    this.iconClasses = {
      base: this.api.styles.inlineToolButton,
      active: this.api.styles.inlineToolButtonActive,
    };
  }

  /**
   * Specifies Tool as Inline Toolbar Tool
   *
   * @returns {boolean}
   */
  static get isInline() {
    return true;
  }

  /**
   * Create button element for Toolbar
   *
   * @returns {HTMLElement}
   */
  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.classList.add(this.iconClasses.base);
    this.button.innerHTML = this.toolboxIcon;

    return this.button;
  }

  /**
   * Wrap/Unwrap selected fragment
   *
   * @param {Range} range - selected fragment
   */
  surround(range) {
    if (!range) {
      return;
    }

    const termWrapper = this.api.selection.findParentTag(this.tag, Superscript.CSS);

    /**
     * If start or end of selection is in the highlighted block
     */
    if (termWrapper) {
      this.unwrap(termWrapper);
    } else {
      this.wrap(range);
    }
  }

  /**
   * Wrap selection with term-tag
   *
   * @param {Range} range - selected fragment
   */
  wrap(range) {
    /**
     * Create a wrapper for highlighting
     */
    const sup = document.createElement(this.tag);

    sup.classList.add(Superscript.CSS);

    /**
     * SurroundContent throws an error if the Range splits a non-Text node with only one of its boundary points
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range/surroundContents}
     *
     * // range.surroundContents(span);
     */
    sup.appendChild(range.extractContents());
    range.insertNode(sup);

    /**
     * Expand (add) selection to highlighted block
     */
    this.api.selection.expandToTag(sup);
  }

  /**
   * Unwrap term-tag
   *
   * @param {HTMLElement} termWrapper - term wrapper tag
   */
  unwrap(termWrapper) {
    /**
     * Expand selection to all term-tag
     */
    this.api.selection.expandToTag(termWrapper);

    const sel = window.getSelection();
    const range = sel.getRangeAt(0);

    const unwrappedContent = range.extractContents();

    /**
     * Remove empty term-tag
     */
    termWrapper.parentNode.removeChild(termWrapper);

    /**
     * Insert extracted content
     */
    range.insertNode(unwrappedContent);

    /**
     * Restore selection
     */
    sel.removeAllRanges();
    sel.addRange(range);
  }

  /**
   * Check and change Term's state for current selection
   */
  checkState() {
    const termTag = this.api.selection.findParentTag(this.tag, Superscript.CSS);

    this.button.classList.toggle(this.iconClasses.active, !!termTag);
  }

  /**
   * Get Tool icon's SVG
   *
   * @returns {string}
   */
  get toolboxIcon() {
    return svgIcon;
  }

  /**
   * Sanitizer rule
   *
   * @returns {{s: {class: string}}}
   */
  static get sanitize() {
    return {
      sup: {
        class: Superscript.CSS,
      },
    };
  }
}

export default Superscript
