/**
 * Build styles
 */
require('./index.css').toString();

/**
 * @typedef {Object} TableOfContentData
 * @description Tool's input and output data format
 */
class TableOfContent {

  /**
   * Notify core that read-only mode is supported
   * @return {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow Tool to have no content
   * @return {boolean}
   */
  static get contentless() {
    return true;
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {{data: TableOfContentData, config: object, api: object}}
   *   data — previously saved data
   *   config - user config for Tool
   *   api - Editor.js API
   */
  constructor({data, config, api}) {
    this.api = api;

    this._CSS = {
      block: this.api.styles.block,
      wrapper: 'ce-table-of-content'
    };

    this._data = {};
    this._element = this.drawView();

    this.data = data;
  }

  /**
   * Create Tool's view
   * @return {HTMLElement}
   * @private
   */
  drawView() {
    let div = document.createElement('DIV');

    div.classList.add(this._CSS.wrapper, this._CSS.block);

    div.append('Table of content anchor will be appended here')

    return div;
  }

  /**
   * Return Tool's view
   * @returns {HTMLDivElement}
   * @public
   */
  render() {
    return this._element;
  }

  /**
   * Extract Tool's data from the view
   * @param {HTMLDivElement} toolsContent - Paragraph tools rendered view
   * @returns {TableOfContentData} - saved data
   * @public
   */
  save(toolsContent) {
    return {};
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @return {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      icon: `<svg width="17" height="17" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 17 17"> <path d="M15.6,13.5v-9c0-2.2-1.8-4-4-4H8.5H5.4c-2.2,0-4,1.8-4,4v9c0,0.3,0,0.7,0.2,1.2c0.6,1.4,2.1,1.8,2.3,1.9 c0.5,0,1.3,0,2.3-0.4c1.3-0.6,2-1.6,2.3-2c0,0,0,0,0,0c0,0,0,0,0,0c0.3,0.4,1,1.4,2.3,2c1,0.4,1.8,0.5,2.3,0.4 c0.2-0.1,1.7-0.5,2.3-1.9C15.6,14.2,15.6,13.8,15.6,13.5z M13.3,13.5c0,1-3.8-1.3-4.8-2.9c-1,1.5-4.8,3.8-4.8,2.9v-9 c0-1,0.8-1.7,1.7-1.7h3.1h3.1c1,0,1.7,0.8,1.7,1.7V13.5z"/> </svg>`,
      title: 'Table Of Content'
    };
  }
}

export default TableOfContent
