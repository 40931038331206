export class ScriptLoader {
    static async load(...src: string[]) {
        return Promise.all(src.map(s=>ScriptLoader._load(s)))
    }

    // https://github.com/tserkov/vue-plugin-load-script/blob/master/index.js
    static async _load(src: string) {
        return new Promise(function (resolve, reject) {
            let shouldAppend = false
            const found = document.querySelector('script[src="' + src + '"]')
            
            if(!found)
                shouldAppend = true
            
            if(found && found.hasAttribute('data-loaded')) {
                resolve(found)
                return
            }
            
            const el = document.createElement('script')
            el.type = 'text/javascript'
            el.async = true
            el.src = src
            shouldAppend = true

            el.addEventListener('error', reject)
            el.addEventListener('abort', reject)
            el.addEventListener('load', function loadScriptHandler() {
                el.setAttribute('data-loaded', 'true')
                resolve(el)
            })

            if (shouldAppend) document.head.appendChild(el)
        })
    }
}