/**
 * Build styles
 */
require('./index.css').toString()

/**
 * @class Course
 * @classdesc Course Tool for Editor.js
 * @property {CourseData} data - Tool`s input and output data
 * @propert {object} api - Editor.js API instance
 *
 * @typedef {object} CourseData
 * @description Course Tool`s input and output data
 * @property {string} id - course`s id
 *
 * @typedef {object} CourseConfig
 * @description Course Tool`s initial configuration
 * @property {string} coursePlaceholder - placeholder to show in course`s id input
 */
class Course {
  /**
   * Notify core that read-only mode is supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      icon: '<svg width="18px" height="18px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 18 18" style="enable-background:new 0 0 18 18;" xml:space="preserve"> <path d="M10.5,3.2H1.8C1,3.2,0.3,3.9,0.3,4.6v8.7c0,0.8,0.6,1.4,1.4,1.4h8.7c0.8,0,1.4-0.6,1.4-1.4V4.6C11.9,3.9,11.3,3.2,10.5,3.2z M16.2,4.3l-3.3,2.3v4.8l3.3,2.3c0.6,0.4,1.5,0,1.5-0.8V5.1C17.7,4.4,16.8,3.9,16.2,4.3L16.2,4.3z"/></svg>',
      title: 'Course',
    }
  }

  /**
   * Tool`s styles
   *
   * @returns {{input: *, baseClass: *, wrapper: string, id: string}}
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      wrapper: 'cdx-course',
      id: 'cdx-course__id',
      url: 'cdx-course__url',
      input: this.api.styles.input
    }
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {{data: CourseData, config: CourseConfig, api: object}}
   *   data — previously saved data
   *   config - user config for Tool
   *   api - Editor.js API
   *   readOnly - read-only mode flag
   */
  constructor({data, config, api, readOnly}) {
    this.api = api
    this.readOnly = readOnly

    this.data = {
      id: data.id || '',
      url: data.url ? decodeURIComponent(data.url) : ''
    }
  }

  /**
   * Create Course Tool container with inputs
   *
   * @returns {Element}
   */
  render() {
    const container = this._make('div', [this.CSS.baseClass, this.CSS.wrapper])

    const hints = this._make('div', [], {
      innerHTML: 'Course Suggestion',
      style: 'margin-bottom: 8px;'
    })
    container.appendChild(hints)

    const course = this._make('input', [this.CSS.input, this.CSS.id], {
      value: this.data.id,
      style: 'margin-bottom: 8px;',
      placeholder: '請輸入一個正確的Course Code'
    })
    container.appendChild(course)

    const hints2 = this._make('div', [], {
      innerHTML: 'Url<br>Must start with "http(s)://" OR "?":<ul><li><span style="background: Orange">https://</span>landing.afterschool.com.hk/gc-202010-aor</li><li style="word-break: break-all;"><span style="background: Orange;">?</span>utm_source=instagram&utm_medium=social&utm_campaign=ig-story</li></ul>',
      style: 'margin-bottom: 8px;'
    })
    container.appendChild(hints2)

    const url = this._make('input', [this.CSS.input, this.CSS.url], {
      value: this.data.url,
      placeholder: 'Destination for Course Card'
    })
    container.appendChild(url)


    return container
  }

  /**
   * Extract Course data from Course Tool element
   *
   * @param {HTMLDivElement} courseElement - element to save
   * @returns {CourseData}
   */
  save(courseElement) {
    const id = courseElement.querySelector(`.${this.CSS.id}`)
    const url = courseElement.querySelector(`.${this.CSS.url}`)
    return Object.assign(this.data, {
      id: id.value.trim(),
      url: encodeURIComponent(url.value.trim())
    })
  }

  /**
   * Sanitizer rules
   */
  static get sanitize() {
    return {
      id: {},
      url: {}
    }
  }

  static get enableLineBreaks() { return true; }

  /**
   * Helper for making Elements with attributes
   *
   * @param  {string} tagName           - new Element tag name
   * @param  {Array|string} classNames  - list or name of CSS classname(s)
   * @param  {object} attributes        - any attributes
   * @returns {Element}
   */
  _make(tagName, classNames = null, attributes = {}) {
    const el = document.createElement(tagName)

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames)
    } else if (classNames) {
      el.classList.add(classNames)
    }

    for (const attrName in attributes) {
      el[attrName] = attributes[attrName]
    }

    return el
  }
}

export default Course
