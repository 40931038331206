/**
 * Build styles
 */
require('./index.css').toString()

class Faq {

    static get isReadOnlySupported() {
        return true
    }

    static get toolbox() {
        return {
            icon: '<svg width="18px" height="18px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"> <g> <path d="M12.3,18.2h-1c-0.7,0-1.2,0.6-1.2,1.2v1.3c0,0.7,0.6,1.2,1.2,1.2h1c0.7,0,1.2-0.6,1.2-1.2v-1.3 C13.6,18.8,13,18.2,12.3,18.2z"/> <path d="M5.5,7.9l1.8,0.2c0.6,0.1,1.1-0.3,1.3-0.8c0.3-0.7,0.6-1.2,1.1-1.6c0.6-0.5,1.5-0.8,2.4-0.8c1,0,1.8,0.3,2.4,0.8 c0.6,0.5,0.9,1.2,0.9,1.9c0,0.5-0.2,1-0.5,1.5c-0.2,0.3-0.9,0.9-2,1.8c-1.1,0.9-1.8,1.7-2.2,2.4c-0.4,0.7-0.5,1.6-0.5,2.4 c0,0.7,0.5,1.3,1.2,1.3h1c0.6,0,1.2-0.5,1.2-1.1c0-0.5,0.1-0.8,0.2-1c0.2-0.4,0.7-0.9,1.5-1.6c1.6-1.3,2.6-2.3,3-3 c0.5-0.7,0.7-1.5,0.7-2.4c0-1.5-0.6-2.8-1.9-4c-1.3-1.1-3-1.7-5.2-1.7c-2.1,0-3.7,0.6-5,1.7c-1.1,1-1.7,2.1-2,3.4 C4.9,7.5,5.1,7.8,5.5,7.9z"/> </g> </svg>',
            title: 'Faq',
        }
    }

    get CSS() {
        return {
            baseClass: this.api.styles.block,
            wrapper: 'cdx-faq',
            input: this.api.styles.input,
            text: 'cdx-faq__text',
        }
    }

    constructor({data, config, api, readOnly}) {
        this.api = api
        this.readOnly = readOnly

        this.data = {
            text: data.text || ''
        }
    }

    render() {
        const container = this._make('div', [this.CSS.baseClass, this.CSS.wrapper])

        const hints = this._make('div', [], {
            innerHTML: '兩個空行分隔問題&答案, 三空行分隔問題&問題',
            style: 'margin-bottom: 8px;'
        })
        container.appendChild(hints)

        const text = this._make('textarea', [this.CSS.input, this.CSS.text], {
            innerHTML: this.data.text,
            style: 'margin-bottom: 8px; min-height: 200px',
            placeholder: 'Faq Text'
        })
        container.appendChild(text)

        return container
    }

    save(faqElement) {
        const text = faqElement.querySelector(`.${this.CSS.text}`)
        return Object.assign(this.data, {
            text: text.value.trim()
        })
    }

    static get sanitize() {
        return {
            text: {}
        }
    }

    _make(tagName, classNames = null, attributes = {}) {
        const el = document.createElement(tagName)

        if (Array.isArray(classNames)) {
            el.classList.add(...classNames)
        } else if (classNames) {
            el.classList.add(classNames)
        }

        for (const attrName in attributes) {
            el[attrName] = attributes[attrName]
        }

        return el
    }
}

export default Faq
