/**
 * Build styles
 */
require('./index.css').toString();

const svgIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 8 13" width="8" height="13">\n' +
    '  <path d="M7.8,2.1C7.7,1.9,7.5,1.8,7.3,1.8H0.7c-0.2,0-0.4,0.1-0.5,0.2C0.1,2.2,0,2.4,0,2.6v1.6c0,0.2,0,0.4,0.2,0.6\n' +
    '\tC0.3,4.9,0.5,5,0.7,5C1,5,1.2,4.9,1.3,4.8c0.1-0.2,0.2-0.3,0.2-0.6V3.3h1.7v2.4v0.4c0,0,0,0-0.1,0H2.7H2.3H1.6H1.2\n' +
    '\tC1,6.1,0.8,6.2,0.6,6.3C0.5,6.5,0.4,6.6,0.4,6.8c0,0.2,0.1,0.4,0.2,0.5C0.8,7.5,1,7.5,1.2,7.5h1.2h0.9v2.2H2.8\n' +
    '\tc-0.2,0-0.4,0.1-0.5,0.2C2.1,10.1,2,10.3,2,10.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2h2.5c0.2,0,0.4-0.1,0.5-0.2\n' +
    '\tC5.9,10.9,6,10.7,6,10.5c0-0.2-0.1-0.4-0.2-0.5C5.6,9.8,5.4,9.8,5.2,9.8H4.8V7.5c0,0,0,0,0.1,0h0.8h1.2c0.2,0,0.4-0.1,0.5-0.2\n' +
    '\tC7.5,7.2,7.6,7,7.6,6.8c0-0.2-0.1-0.4-0.2-0.5C7.2,6.2,7,6.1,6.8,6.1H6.4H5.7H5.3H4.8V5.7V3.3h1.7v0.9c0,0.2,0.1,0.4,0.2,0.6\n' +
    '\tC6.8,4.9,7,5,7.2,5c0.2,0,0.4-0.1,0.6-0.2C7.9,4.6,8,4.4,8,4.2V2.6C8,2.4,7.9,2.2,7.8,2.1z"/>\n' +
    '</svg>\n'

/**
 * Strikethrough Tool for the Editor.js
 *
 * Allows to wrap inline fragment and style it somehow.
 */
class Strikethrough {
  /**
   * Class name for term-tag
   *
   * @type {string}
   */
  static get CSS() {
    return 'cdx-strikethrough';
  };

  /**
   * @param {{api: object}}  - Editor.js API
   */
  constructor({ api }) {
    this.api = api;

    /**
     * Toolbar Button
     *
     * @type {HTMLElement|null}
     */
    this.button = null;

    /**
     * Tag represented the term
     *
     * @type {string}
     */
    this.tag = 'S';

    /**
     * CSS classes
     */
    this.iconClasses = {
      base: this.api.styles.inlineToolButton,
      active: this.api.styles.inlineToolButtonActive,
    };
  }

  /**
   * Specifies Tool as Inline Toolbar Tool
   *
   * @returns {boolean}
   */
  static get isInline() {
    return true;
  }

  /**
   * Create button element for Toolbar
   *
   * @returns {HTMLElement}
   */
  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.classList.add(this.iconClasses.base);
    this.button.innerHTML = this.toolboxIcon;

    return this.button;
  }

  /**
   * Wrap/Unwrap selected fragment
   *
   * @param {Range} range - selected fragment
   */
  surround(range) {
    if (!range) {
      return;
    }

    const termWrapper = this.api.selection.findParentTag(this.tag, Strikethrough.CSS);

    /**
     * If start or end of selection is in the highlighted block
     */
    if (termWrapper) {
      this.unwrap(termWrapper);
    } else {
      this.wrap(range);
    }
  }

  /**
   * Wrap selection with term-tag
   *
   * @param {Range} range - selected fragment
   */
  wrap(range) {
    /**
     * Create a wrapper for highlighting
     */
    const s = document.createElement(this.tag);

    s.classList.add(Strikethrough.CSS);

    /**
     * SurroundContent throws an error if the Range splits a non-Text node with only one of its boundary points
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range/surroundContents}
     *
     * // range.surroundContents(span);
     */
    s.appendChild(range.extractContents());
    range.insertNode(s);

    /**
     * Expand (add) selection to highlighted block
     */
    this.api.selection.expandToTag(s);
  }

  /**
   * Unwrap term-tag
   *
   * @param {HTMLElement} termWrapper - term wrapper tag
   */
  unwrap(termWrapper) {
    /**
     * Expand selection to all term-tag
     */
    this.api.selection.expandToTag(termWrapper);

    const sel = window.getSelection();
    const range = sel.getRangeAt(0);

    const unwrappedContent = range.extractContents();

    /**
     * Remove empty term-tag
     */
    termWrapper.parentNode.removeChild(termWrapper);

    /**
     * Insert extracted content
     */
    range.insertNode(unwrappedContent);

    /**
     * Restore selection
     */
    sel.removeAllRanges();
    sel.addRange(range);
  }

  /**
   * Check and change Term's state for current selection
   */
  checkState() {
    const termTag = this.api.selection.findParentTag(this.tag, Strikethrough.CSS);

    this.button.classList.toggle(this.iconClasses.active, !!termTag);
  }

  /**
   * Get Tool icon's SVG
   *
   * @returns {string}
   */
  get toolboxIcon() {
    return svgIcon;
  }

  /**
   * Sanitizer rule
   *
   * @returns {{s: {class: string}}}
   */
  static get sanitize() {
    return {
      s: {
        class: Strikethrough.CSS,
      },
    };
  }
}

export default Strikethrough
